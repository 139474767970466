export const en = {
	dir: "ltr",
	sign_in: "Sign In",
	login: "Login",
	join_for_free: "Join for Free",
	search: "Search",
	cancel: "Cancel",
	welcome_back: "Welcome back!",
	please_signIn: "Please sign in to continue.",
	email_or_phone: "E-mail or phone number",
	password: "Password",
	forgot_password: "Forgot password?",
	or: "OR",
	sign_up: "Sign Up",
	dont_have_account: "Don't have an account?",
	create_new_account: "Create New Account",
	free_signUp: "It's free to signup and only takes a minute.",
	full_name: "Full name",
	email: "E-mail",
	password_confirmation: "Password Confirmation",
	already_have_account: "Already have an account?",
	by_clicking_agree: "By clicking Create an account below, you agree to our",
	terms_of_service: "terms of service",
	and: "and",
	privacy_statement: "privacy statement",
	with_google: "With Google",
	with_facebook: "With Facebook",
	type_email_phone: "Type your e-mail or phone number",
	type_password: "Type your password",
	enter_your_name: "Enter your name",
	Join_As: "Join as",
	Teacher: "Teacher",
	Student: "Student",
	Phone_Number: "Phone Number",
	Reset_Your_Password: "Reset your password",
	Enter_Phone_Number_We_Send_OTP:
		"Enter your Phone number and we will send you an OTP to reset your password.",
	Reset_Password: "Reset Password",
	Create_New_Password: "Create a new password",
	Create_New_Secure_Password: "Create a new secure password",
	Change_Password: "Change password",
	Verify_Your_Phone: "Verify your phone.",
	Enter_Verification_Code_To_Phone_Number:
		"Enter the verification code which we send to your phone number",
	Phone: "phone",
	Verification_Code: "Verification code",
	Verify: "Verify",
	Did_I_Not_Get_The_Code: "Did I not get the code?",
	You_Can_Resend_New_OTP_In: "you can resend new OTP in",
	Resend: "Resend",
};

export const ar = {
	dir: "rtl",
	sign_in: "تسجيل دخول",
	login: "تسجيل دخول",
	join_for_free: "انضم مجانا",
	search: "بحث",
	cancel: "إلغاء",
	welcome_back: "مرحبًا بعودتك!",
	please_signIn: "من فضلك سجل دخولك للمتابعة.",
	email_or_phone: "البريد الإلكتروني أو رقم الهاتف",
	password: "كلمة المرور",
	forgot_password: "هل نسيت كلمة السر؟",
	or: "أو",
	sign_up: "تسجيل حساب",
	dont_have_account: "ليس لديك حساب؟",
	create_new_account: "انشاء حساب جديد",
	free_signUp: "التسجيل مجاني ولا يستغرق سوى دقيقة واحدة.",
	full_name: "الاسم الكامل",
	email: "البريد إلكتروني",
	password_confirmation: "تأكيد كلمة المرور",
	already_have_account: "هل لديك حساب؟",
	by_clicking_agree: "بالنقر فوق إنشاء حساب أدناه ، فإنك توافق على",
	terms_of_service: "شروط الخدمة",
	and: "و",
	privacy_statement: "بيان الخصوصية",
	with_google: "بواسطة جوجل",
	with_facebook: "بواسطة فيسبوك",
	type_email_phone: "اكتب بريدك الإلكتروني أو رقم هاتفك",
	type_password: "اكتب كلمة المرور الخاصة بك",
	enter_your_name: "أدخل أسمك",
	Join_As: "انضمام ك",
	Teacher: "مدرس",
	Student: "طالب",
	Phone_Number: "رقم الهاتف",
	Reset_Your_Password: "إعادة تعيين كلمة المرور الخاصة بك",
	Enter_Phone_Number_We_Send_OTP:
		"أدخل رقم هاتفك وسنرسل لك OTP لإعادة تعيين كلمة المرور الخاصة بك.",
	Reset_Password: "إعادة تعيين كلمة المرور",
	Create_New_Password: "إنشاء كلمة مرور جديدة",
	Create_New_Secure_Password: "إنشاء كلمة مرور آمنة جديدة",
	Change_Password: "تغيير كلمة المرور",
	Verify_Your_Phone: "تحقق من هاتفك.",
	Enter_Verification_Code_To_Phone_Number:
		"أدخل رمز التحقق الذي نرسله إلى رقم هاتفك",
	Phone: "هاتف",
	Verification_Code: "رمز التحقق",
	Verify: "تحقق",
	Did_I_Not_Get_The_Code: "لم أحصل على الكود؟",
	You_Can_Resend_New_OTP_In: "يمكنك إعادة إرسال رمز تحقق جديد في",
	Resend: "إعادة الإرسال",
};
