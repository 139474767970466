export const en = {
	Home: "Home",
	Find_Teacher: "Find a teacher",
	Find_Center: "Find a center",
	About_Us: "About Us",
	Easy_Education:
		"Easy/Effective experience that changes your thoughts about Education !",
	Search_All_Teachers: "Search for All Teachers",
	Popular: "Popular",
	Our_Featured_Teachers: "Our Featured Teachers",
	Filter: "Filter",
	View_All: "View All",
	Create_Website_For_Teacher: "Create website for teacher in 30 seconds",
	Get_Started: "Get started",
	Best_Teachers_In: "Best Teachers in",
	Students: "Students",
	Teachers: "Teachers",
	Centers: "Centers",
	Groups: "Groups",
	Countries: "Countries",
	Review: "Review",
	Our_Centers: "Our Centers",
	IBNABBS_Green_Line: "IBNABBS Green Line",
	Parents: "Parents",
	Super_Easy_To_Use: "Super Easy to use",
	Training_Management: "Training Management",
	Analytics: "Analytics",
	Content_Management: "Content Management",
	Support: "Support",
	Want_To_Join_Teacher: "Do you want to join us as a teacher?",
	Join_Us: "Join us",
	Want_To_Join_Center: "Do you want to join us as a center?",
	Blog: "Blog",
	Careers: "Careers",
	Contact_Us: "Contact Us",
	Join_Us_As_Teacher: "Join us as a teacher",
	Join_Us_As_Center: "Join us as a center",
	Terms_Conditions: "Terms & conditions",
	Privacy_Policy: "Privacy policy",
	Help: "Help",
	Copy_Rights: "© 2024 IBNABBAS. All rights reserved.",
	SAR: "SAR",
	Type: "Type",
	Level: "Level",
	Subject: "Subject",
	Find_Teacher_Online: "Find your teacher online",
	Select: "Select...",
	Select_Governate: "Select Governate",
	Select_City: "Select City",
	Select_District: "Select District",
	View_Profile: "View Profile",
};

export const ar = {
	Home: "الرئيسية",
	Find_Teacher: "ابحث عن معلم",
	Find_Center: "البحث عن مركز",
	About_Us: "معلومات عنا",
	Easy_Education: "تجربة سهلة/فعّالة ستغير أفكارك حول التعليم!",
	Search_All_Teachers: "البحث عن جميع المعلمين",
	Popular: "شائع",
	Our_Featured_Teachers: "معلمينا المميزين",
	Filter: "تصفية",
	View_All: "عرض الكل",
	Create_Website_For_Teacher: "إنشاء موقع للمعلم في 30 ثانية",
	Get_Started: "ابدأ",
	Best_Teachers_In: "أفضل المعلمين في",
	Students: "طلاب",
	Teachers: "المعلمين",
	Centers: "المراكز",
	Groups: "المجموعات",
	Countries: "دول",
	Review: "آراء",
	Our_Centers: "مراكزنا",
	IBNABBS_Green_Line: "الخط الأخضر ل ابن عباس",
	Parents: "الآباء",
	Super_Easy_To_Use: "سهل الاستخدام للغاية",
	Training_Management: "إدارة التدريب",
	Analytics: "التحليلات",
	Content_Management: "إدارة المحتوى",
	Support: "الدعم",
	Want_To_Join_Teacher: "هل تريد الانضمام إلينا كمدرس؟",
	Join_Us: "انضم إلينا",
	Want_To_Join_Center: "هل تريد الانضمام إلينا كمركز؟",
	Blog: "مدونة",
	Careers: "وظائف",
	Contact_Us: "اتصل بنا",
	Join_Us_As_Teacher: "انضم إلينا كمدرس",
	Join_Us_As_Center: "انضم إلينا كمركز",
	Terms_Conditions: "الشروط والأحكام",
	Privacy_Policy: "سياسة الخصوصية",
	Help: "مساعدة",
	Copy_Rights: "© 2024 ابن عباس. جميع الحقوق محفوظة.",
	SAR: "ريال",
	Type: "النوع",
	Level: "المرحلة",
	Subject: "المادة",
	Find_Teacher_Online: "ابحث عن مدرسك عبر الإنترنت",
	Select: "اختر...",
	Select_Governate: "اختر المحافظة",
	Select_City: "اختر المدينة",
	Select_District: "اختر المنطقة",
	View_Profile: "عرض الملف الشخصي",
};
